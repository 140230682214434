import { lazy } from "react";
import { ModuleLayout } from "../Enums";

const Home = lazy(() => import("./Home"));
const StripeIntegration = lazy(() => import("./StripeIntegration"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const BakerProfile = lazy(() => import("./BakerProfile"));

export const Pages = [
  {
    path: "/",
    component: Home,
    layout: ModuleLayout.WITHOUT_HEADER,
    report: {
      name: "",
    },
    exact: true,
    scroll: true,
  },
  {
    path: "/stripe-integration",
    component: StripeIntegration,
    layout: ModuleLayout.WITHOUT_HEADER,
    report: {
      name: "",
    },
    exact: true,
    scroll: true,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    layout: ModuleLayout.WITHOUT_HEADER,
    report: {
      name: "",
    },
    exact: true,
    scroll: true,
  },
  {
    path: "/:id",
    component: BakerProfile,
    layout: ModuleLayout.WITHOUT_HEADER,
    report: {
      name: "",
    },
    exact: true,
    scroll: true,
  },
];
