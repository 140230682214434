import React from "react";
import { Loader } from "semantic-ui-react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
  },
}));

export const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Loader active>Loading</Loader>
    </div>
  );
};
