export enum DocumentType {
  EMPTY = "",
  COMPANY = "COMPANY",
  COMPANY_SHIP_METHOD = "COMPANY_SHIP_METHOD",
  COMPANY_USER = "COMPANY_USER",
  CONNECTION = "CONNECTION",
  CONTACT = "CONTACT",
  DISCUSSION = "DISCUSSION",
  FILE_STORAGE = "FILE_STORAGE",
  HISTORY = "HISTORY",
  INVOICE = "INVOICE",
  MEMBER = "MEMBER",
  MESSAGE = "MESSAGE",
  PRODUCT = "PRODUCT",
  PURCHASE_ORDER = "PURCHASE_ORDER",
  PURCHASE_ORDER_ITEM = "PURCHASE_ORDER_ITEM",
  ROLE = "ROLE",
  SALES_ORDER = "SALES_ORDER",
  SHIP_TO_ADDRESS = "SHIP_TO_ADDRESS",
  SHIP_METHOD = "SHIP_METHOD",
  USER = "USER",
  VENDOR = "VENDOR",
}

export enum QueryType {
  ASSOCIATED_COMPANIES = "ASSOCIATED_COMPANIES",
  COMPANIES = "COMPANIES",
  COMPANY_USERS = "COMPANY_USERS",
  CONNECTIONS = "CONNECTIONS",
  CONTACTS = "CONTACTS",
  DISCUSSIONS = "DISCUSSIONS",
  HISTORY = "HISTORY",
  LOGIN = "LOGIN",
  MEMBERS = "MEMBERS",
  MESSAGES = "MESSAGES",
  PRODUCTS = "PRODUCTS",
  PURCHASE_ORDERS = "PURCHASE_ORDERS",
  PURCHASE_ORDER_ITEMS = "PURCHASE_ORDER_ITEMS",
  ROLES = "ROLES",
  SHIP_TO_ADDRESSES = "SHIP_TO_ADDRESSES",
  SIGN_UP = "SIGN_UP",
  VENDORS = "VENDORS",
}

export enum EntityType {
  COMPANY = "COMPANY",
  COMPANY_USER = "COMPANY_USER",
  COMPANY_SHIP_METHOD = "COMPANY_SHIP_METHOD",
  CONNECTION = "CONNECTION",
  CONTACT = "CONTACT",
  PURCHASE_ORDER_STATUS = "PURCHASE_ORDER_STATUS",
  ROLE = "ROLE",
  SHIP_METHOD = "SHIP_METHOD",
  SHIP_TO_ADDRESS = "SHIP_TO_ADDRESS",
  VENDOR_CONNECTED = "VENDOR_CONNECTED",
  VENDOR = "VENDOR",
  VISIBILITY = "VISIBILITY",
}

export enum CommandType {
  ADD_MEMBER = "ADD_MEMBER",
  CREATE_SETUP_INTENT = "CREATE_SETUP_INTENT",
  DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
  HEADER_DATA = "HEADER_DATA",
  REMOVE_MEMBER = "REMOVE_MEMBER",
}

export enum DateTimeFormat {
  DEFAULT = "D ttt",
}

export enum ModuleLayout {
  WITH_HEADER = "WITH_HEADER",
  WITHOUT_HEADER = "WITHOUT_HEADER",
  WITH_PUBLIC_HEADER = "WITH_PUBLIC_HEADER",
}

export enum AuthStatusType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum DocumentFlowType {
  INBOX = "INBOX",
  DRAFTS = "DRAFTS",
  SENT = "SENT",
}

export enum ReportRowType {
  GROUP = "g",
  ITEM = "i",
}

// export enum SentType {
//   DRAFT = "DRAFT",
//   SENT = "SENT",
// }

export enum VisibilityType {
  VISIBLE = "VISIBLE",
  NOT_VISIBLE = "NOT_VISIBLE",
}

export enum StripeSubscriptionStatus {
  TRIALING = "trialing",
  ACTIVE = "active",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
}
