import { createSlice } from "@reduxjs/toolkit";
import { set, setWith, pick } from "lodash-es";
import { ObjectType } from "../Types";
import { RootState } from "./store";

type AuthSliceType = {
  jwt?: string | null;
  expiration?: number | null;
  idToken?: string | null;
  refreshToken?: string | null;
  userId?: string | null;
  user?: ObjectType | null;
  email?: string | null;
  verifyMode?: "account" | "password" | null;
  companyId: number;
  company?: ObjectType | null;
};
export const authSlice = createSlice({
  name: "auth",
  initialState: {
    jwt: null,
    expiration: null,
    idToken: null,
    refreshToken: null,
    userId: null,
    user: null,
    email: null,
    verifyMode: null,
    companyId: 0,
    company: null,
  } as AuthSliceType,
  reducers: {
    setJwt: (state, action) => {
      state.jwt = action.payload;
    },
    setExpiration: (state, action) => {
      state.expiration = action.payload;
    },
    setIdToken: (state, action) => {
      state.idToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setVerifyMode: (state, action) => {
      state.verifyMode = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateUser: (state, action) => {
      const newUser = { ...state.user };
      Object.entries(action.payload).forEach(([key, value]) => {
        set(newUser, key, value);
      });
      state.user = newUser;
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload.companyId;
    },
    setCompany: (state, action) => {
      state.company = action.payload;
    },
  },
});

export const {
  setJwt,
  setUserId,
  setUser,
  setExpiration,
  setEmail,
  updateUser,
  setIdToken,
  setRefreshToken,
  setVerifyMode,
  setCompanyId,
  setCompany,
} = authSlice.actions;
export const getVerifyMode = (state: RootState) => state.auth.verifyMode;
export const getEmail = (state: RootState) => state.auth.email;
export const getJwt = (state: RootState) => state.auth.jwt;
export const getIdToken = (state: RootState) => state.auth.idToken;
export const getRefreshToken = (state: RootState) =>
  state.auth.refreshToken as string;
export const getUserId = (state: RootState) => state.auth.userId;
export const getUser = (state: RootState) => state.auth.user;
export const getExpiration = (state: RootState) => state.auth.expiration;
export const getCompanyId = (state: RootState) => state.auth.companyId;
export const getCompany = (state: RootState) => state.auth.company;
export default authSlice.reducer;
